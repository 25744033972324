<template>
  <Calendar
    @change="fetchData"
    :events="eventsFiltered"
    :holidays="holidays"
    :loading="loading"
  >
    <DivisionFilter v-model="filterDivision" />
  </Calendar>
</template>

<script>
import { defineComponent } from "vue";
import Calendar from "@/components/Calendar";
import DivisionFilter from "@/components/DivisionFilter";
import { isCurrent, isFuture, isPast } from "common/utils/date.js";
export default defineComponent({
  name: "EventCalendar",
  components: { Calendar, DivisionFilter },
  data() {
    return {
      loading: false,
      filterDivision: [0],
      events: [],
      holidays: [],
    };
  },
  computed: {
    eventsFiltered() {
      return this.eventsMapped.filter(
        (item) =>
          item.division == null ||
          this.filterDivision.includes(0) ||
          this.filterDivision.includes(item.division.id)
      );
    },
    eventsMapped() {
      return this.events.map((el) => {
        return {
          ...el,
          changed: this.isNew(el),
          current: this.isCurrent(el),
          past: this.isPast(el),
          future: this.isFuture(el),
        };
      });
    },
  },
  methods: {
    isCurrent,
    isFuture,
    isPast,
    isNew(item) {
      const deadline = new Date(this.$_currentTerm.startDate).getTime();
      const value = new Date(item.lastModified).getTime();
      return deadline < value;
    },
    async fetchData(start, end) {
      this.loading = true;
      this.events = await this.apiList({
        resource: "course/event",
        query: `startDate=${start}&endDate=${end}`,
      });

      this.holidays = await this.apiList({
        resource: "common/holiday/",
        query: `startDate=${start}&endDate=${end}`,
      });
      this.loading = false;
    },
  },
});
</script>
