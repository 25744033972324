<template>
  <v-card tile>
    <v-system-bar :color="color" window
      >{{ value.type.description }}
      {{
        value.type.code == "exam" && value.plannedExam ? "(eingetragen)" : ""
      }}
      <v-spacer></v-spacer
      ><v-btn small icon @click="$emit('close')"
        ><v-icon small>mdi-close</v-icon></v-btn
      ></v-system-bar
    >
    <v-card-text class="title pt-8 pb-0 text-center">
      {{ value.type.description }}: {{ value.title }}
    </v-card-text>
    <v-card-text class="subtitle pt-0 text-center">
      {{ value.description }}
    </v-card-text>

    <v-card-text class="display text-center">
      <v-avatar :color="color">
        <v-icon color="white">{{ icon }}</v-icon>
      </v-avatar>
    </v-card-text>
    <v-card-text class="subtitle pt-0 text-center">
      <DateValue :value="value.date"></DateValue>,
      {{ value.period.startTime }} –
      {{ value.period.endTime }}
    </v-card-text>
    <v-list>
      <CourseItem :value="value.course"></CourseItem>
      <v-list-item v-if="value.smartLearn">
        <v-list-item-avatar><v-icon>mdi-laptop</v-icon></v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Smartlearn</v-list-item-title>
          <v-list-item-subtitle>Laptop mitbringen</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <PersonItem
        v-for="teacher in value.course.teachers"
        :key="teacher.id"
        :value="teacher"
      ></PersonItem>
    </v-list>
    <v-divider />
    <v-card-actions>
      <v-btn block text color="primary" @click="$emit('close')">
        Schliessen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";

import CourseItem from "@/components/CourseItem.vue";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "common/components/PersonItem.vue";

import { noteColor, noteIcon } from "common/utils/icons.js";

export default defineComponent({
  name: "NoteSheet",
  components: { CourseItem, DateValue, PersonItem },
  props: {
    value: null,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    color() {
      return noteColor(this.value);
    },
    icon() {
      return noteIcon(this.value, true);
    },
  },
});
</script>
