<template>
  <v-list-item
    v-bind="$attrs"
    v-on="{ ...$listeners }"
    v-if="value"
    :to="{ name: 'CourseRegister', params: { id: value.id } }"
  >
    <v-list-item-avatar
      ><v-icon>{{ icon }}</v-icon></v-list-item-avatar
    >
    <v-list-item-content>
      <v-list-item-title>
        {{ value.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ formatDatespan(value.startDate, value.endDate) }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <slot />
  </v-list-item>
</template>
<script>
import { defineComponent } from "vue";
import { formatDatespan } from "common/utils/date.js";
import { subjectIcon } from "common/utils/icons.js";
export default defineComponent({
  props: {
    value: null,
  },
  computed: {
    icon() {
      if (!this.value) {
        return "";
      }
      return subjectIcon(this.value.subject);
    },
  },
  methods: {
    formatDatespan,
  },
});
</script>
